$enable-ltr: true;

@import "react-toastify/dist/ReactToastify.css";
@import "react-confirm-alert/src/react-confirm-alert.css";
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

body {
  font-family: "Nunito", sans-serif;
  font-family: "Ubuntu", sans-serif;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #4266ce;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: #4266ce;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}